import { cn } from 'class-merge';
import { CheckboxColor } from '~constants/etc';
import { objectsToString } from '~libs/objects-to-string';

const getCheckboxStyle = (
  checkbox: Record<string, any>,
): Record<string, any> => {
  const { styles, defaultProps, className, color, labelProps } = checkbox;
  const { base, colors, icons } = styles;

  color ?? defaultProps?.color;
  className ?? defaultProps?.className;

  const rootClasses = objectsToString(base?.root);
  const containerClasses = objectsToString(base?.container);
  const disabledClasses = objectsToString(base?.disabled);
  const inputClasses = cn(
    objectsToString(base?.input),
    objectsToString(colors[color || CheckboxColor.DEFAULT]),
    className,
  );
  const iconClasses = cn(
    objectsToString(base?.icon),
    objectsToString(icons[color || CheckboxColor.DEFAULT]),
  );
  const labelClasses = cn(objectsToString(base?.label), labelProps?.className);

  return {
    rootClasses,
    containerClasses,
    inputClasses,
    iconClasses,
    labelClasses,
    disabledClasses,
  };
};

export default getCheckboxStyle;
