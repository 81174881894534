'use client';

import type { FC } from 'react';
import React from 'react';
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Link from 'next/link';
import Joi from 'joi';
import {
  LockOutlined,
  StarsBorderOutlined,
  UserOutlined,
} from '@packages/icons-react';
import jwt from 'jsonwebtoken';
import Image from 'next/image';
import dayjs from 'dayjs';
import { signIn } from 'next-auth/react';
import { setCookie } from 'nookies';
import { useTranslation } from 'react-i18next';
import { useParams } from 'next/navigation';
import appConfig from '~build-config/config.json';
import HttpErrorHandler from '~components/http-error-handler';
import { Input } from '~components/input';
import Checkbox from '~components/checkbox';
import PageUrls from '~constants/page-urls';
import { Button } from '~components/button';
import { ButtonColors } from '~constants/etc';
import { axiosInstance } from '~libs/axios-instance';
import { encrypt } from '~libs/encrypt';

interface UpdateSignInFormProps {
  username: string;
  password: string;
}

const LoginClientPage: FC = () => {
  const { t } = useTranslation(['login', 'buttons', 'forms']);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { locale } = useParams() as {
    locale: string;
  };

  const schemaErrorsSignInForm = Joi.object({
    username: Joi.string()
      .required()
      .messages({
        'any.required': t('forms:error-username-empty'),
        'string.empty': t('forms:error-username-empty'),
      }),
    password: Joi.string().messages({
      'any.required': t('forms:error-password-empty'),
      'string.empty': t('forms:error-password-empty'),
    }),
  }).unknown(true);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<UpdateSignInFormProps>({
    resolver: joiResolver(schemaErrorsSignInForm),
  });

  const handleRequestLogin: SubmitHandler<UpdateSignInFormProps> = async (
    values,
  ) => {
    let response;
    const { username, password } = values;
    try {
      if (!executeRecaptcha) {
        return;
      }
      const token = await executeRecaptcha('login');
      const encryptPassword = encrypt(password);
      response = await axiosInstance.post('/authentication/login', {
        username: username.trim().toLowerCase(),
        password: encryptPassword,
        token,
      });
    } catch (error) {
      HttpErrorHandler(error);
    }
    if (response) {
      const jwtDecoded = jwt.decode(response.data.accessToken);
      setCookie(undefined, 'access_token', response.data.accessToken, {
        maxAge: dayjs(jwtDecoded.exp * 1000).diff(dayjs(), 's'),
        path: '/',
      });
      await signIn('credentials', {
        accessToken: response.data.accessToken,
      });
    }
  };

  return (
    <form
      className="relative z-10 mt-16 flex w-full flex-col gap-3"
      onSubmit={handleSubmit(handleRequestLogin)}
    >
      <Input
        {...register('username')}
        errors={
          errors.username?.message ? [errors.username.message] : undefined
        }
        placeholder={t('forms:place-holder-username')}
        prefix={<UserOutlined />}
      />
      <Input
        {...register('password')}
        errors={
          errors.password?.message ? [errors.password.message] : undefined
        }
        placeholder={t('forms:place-holder-password')}
        prefix={<StarsBorderOutlined />}
        type="password"
      />
      <div className="text-color-secondary my-2 flex justify-between">
        <label className="flex cursor-pointer items-center" htmlFor="remember">
          <Checkbox id="remember" /> {t('login:remember-text')}
        </label>
        <Link
          className="flex items-center gap-1"
          href={`/${locale}${PageUrls.FORGOT_PASSWORD}`}
        >
          <LockOutlined /> {t('login:forgot-password-text')}
        </Link>
      </div>
      <Button loading={isSubmitting} type="submit">
        {t('buttons:btn-login')}
      </Button>
      <Button
        color={ButtonColors.DEFAULT}
        href={`/${locale}${PageUrls.REGISTER}`}
      >
        {t('buttons:btn-register')}
      </Button>
      <Button
        color={ButtonColors.TRANSPARENT}
        href={`/${locale}${PageUrls.CONTACT}`}
      >
        {t('buttons:btn-contact')}
      </Button>
    </form>
  );
};

const PageWithGoogleRecaptcha = (): React.JSX.Element => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.GOOGLE_RECAPTCHA_SITEKEY!}
    >
      <LoginClientPage />
      {appConfig.imageCoverUnauthorized ? (
        <Image
          alt="mascot-unauthorized"
          className="absolute -bottom-20 sm:min-h-[300px] sm:min-w-[300px]"
          draggable={false}
          height={220}
          src={appConfig.imageCoverUnauthorized}
          unoptimized
          width={220}
        />
      ) : null}
    </GoogleReCaptchaProvider>
  );
};

export default PageWithGoogleRecaptcha;
