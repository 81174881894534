import { cn } from 'class-merge';
import { CheckOutlined } from '@packages/icons-react';
import type { ComponentProps } from 'react';
import { forwardRef } from 'react';
import type { CheckboxColor } from '~constants/etc';
import { useTheme } from '~context/theme';
import getCheckboxStyle from '~libs/get-checkbox-style';

export interface CheckboxProps extends ComponentProps<'input'> {
  color?: CheckboxColor;
  label?: string;
  className?: string;
  labelProps?: Record<string, string>;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, disabled, labelProps, ...rest }, ref) => {
    const { checkbox } = useTheme();
    const {
      containerClasses,
      rootClasses,
      inputClasses,
      iconClasses,
      labelClasses,
      disabledClasses,
    } = getCheckboxStyle({
      ...rest,
      labelProps,
      ...checkbox,
    });

    return (
      <div className={rootClasses} ref={ref}>
        <label className={containerClasses} htmlFor={rest.id || 'checkbox'}>
          <input
            {...rest}
            className={cn(inputClasses, disabled && disabledClasses)}
            disabled={disabled}
            id={rest.id || 'checkbox'}
            type="checkbox"
          />
          <div className={iconClasses}>
            <CheckOutlined />
          </div>
        </label>
        {label ? (
          <label
            className={cn(labelClasses, disabled && disabledClasses)}
            htmlFor={rest.id || 'checkbox'}
          >
            {label}
          </label>
        ) : null}
      </div>
    );
  },
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
